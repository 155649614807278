@media (width >= 1024px) {
	.home-hero {
		height: 100dvh;
		max-height: 100dvh;
		padding-bottom: var(--space-2);
		display: flex;
		flex-direction: column;

		.home-hero-title {
			flex-grow: 0;
			flex-shrink: 1;
			font-size: 7.75vw;
			white-space: nowrap;
		}
	}
	.home-about {
		.home-about-content {
		width: 25%;
		margin-left: 40vw;
		
		& > div {
			margin-bottom: var(--space-2);
		}
		}
	
		p {
			font-size: var(--body-large);
		}
	}
	.home-work-wrapper {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	.home-work-title {
		margin: var(--space-2) var(--space-2);
	}
	.home-work-view {
		display: flex;
		justify-content: center;
		margin-top: var(--space-2);
	}
}

@media (width < 1024px) {
	.home-hero {
		height: 100dvh;
		max-height: 100dvh;
		padding-bottom: var(--space-2);
		display: flex;
		flex-direction: column;
	
		.home-hero-title {
			font-size: 7.5vw;
			flex-grow: 0;
			flex-shrink: 1;
			white-space: nowrap;
		}
	}
	.home-about {
		.home-about-content {
		width: 50%;
		margin-left: 40vw;
		
		& > div {
			margin-bottom: var(--space-2);
		}
		}
	
		p {
			font-size: var(--body-large);
		}
	}
	.home-work-wrapper {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	.home-work-title {
		margin: var(--space-2) var(--space-2);
	}
	.home-work-view {
		display: flex;
		justify-content: center;
		margin-top: var(--space-2);
	}
}

.home-hero-img {
	height: 80vh;
	object-fit: cover;
	width: 100%;
	border-bottom-left-radius: 4rem;
	border-bottom-right-radius: 4rem;
	margin-bottom: var(--space-1);
}