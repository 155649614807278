.footer {
    display: flex;
    justify-content: space-between;
    padding-bottom: var(--space-4);

    & > div {
        display: flex;
        flex-direction: column;

        & > p {
            font-size: var(--body-large);
        }
    }
}