@media (width < 1000px) {
	.about-hero {
		display: flex;
		flex-direction: column;
		gap: var(--space-1);
		width: 60%;
		margin-top: 10vh;
	}
	.about-content {
		display: flex;
		flex-direction: column;
		gap: var(--space-2);
		width: 60%;
		margin-left: 35%;
		
		& > div {
			display: flex;
			flex-direction: column;
			gap: var(--space-1);
		}
	
		p {
			font-size: var(--body-large);
		}
	}
}
@media (width >= 1000px) {
	.about-hero {
		display: flex;
		flex-direction: column;
		gap: var(--space-1);
		width: 40vw;
		margin-top: 30vh;
		margin-left: var(--space-4);
	}
	.about-content {
		display: flex;
		flex-direction: column;
		gap: var(--space-2);
		width: 35%;
		margin-left: 45%;
		
		& > div {
			display: flex;
			flex-direction: column;
			gap: var(--space-1);
		}
	
		p {
			font-size: var(--body-large);
		}
	}
}


.about-hero-img {
	aspect-ratio: 7/3;
	width: 100%;
	object-fit: cover;
}
.about-content-img {
	object-fit: cover;
	aspect-ratio: 5/3;
	width: 100%;
}