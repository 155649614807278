header {
	width: 100%;
	position: fixed;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: end;
	padding: var(--space-1) 7.5%;
	z-index: 5;
	top: 0;
	left: 0;
}
.links {
	display: flex;
	flex-direction: row;
	gap: var(--space-1);

	a {
		position: relative;
		font-size: var(--body-large);
		text-transform: uppercase;
		transition: 0.5s color ease-out;

		&:not(.selected) {
			color: var(--neutral-dark);
		}

		&:not(.selected)::after {
			content: '';
			position: absolute;
			right: 0;
			bottom: -8px;
			height: 4px;
			width: 0;
			transform-origin: right;
			background-color: var(--neutral-dark);
			transition: 0.5s all ease-out;
		}

		&.selected {
			color: var(--secondary);
		}

		&.selected::after {
			content: '';
			position: absolute;
			left: 0;
			bottom: -8px;
			height: 4px;
			width: 100%;
			background-color: var(--secondary);
			transform-origin: right;
			transition: 0.5s all ease-out;
		}
	}
}
.link-home {
	font-size: var(--body-large);
}