.work-title
{
	margin-top: var(--space-5);
	margin-bottom: var(--space-1);
	margin-left: var(--space-4);
}

.work-description {
	margin-left: var(--space-5);
}

.project {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: var(--space-2) var(--space-2);
	border-bottom: 1px solid var(--neutral-dark);
	transition: 0.15s all;

	&:first-of-type {
		border-top: 1px solid var(--neutral-dark);
	}

	&:hover {
		color: var(--secondary);
		padding: var(--space-2) var(--space-1);
	}
}
.work-hero > .projects {
	margin-top: var(--space-4);
}


@media (width < 640px) {
	.work-title {
		margin-top: var(--space-5);
		margin-bottom: var(--space-1);
		margin-left: 0;
	}
	.work-description {
		margin-left: var(--space-1);
	}
	.project {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: start;
		gap: var(--space-1);
		padding: var(--space-2) var(--space-2);
		border-bottom: 1px solid var(--neutral-dark);
		transition: 0.25s all ease-out;
	
		&:first-of-type {
			border-top: 1px solid var(--neutral-dark);
		}
	
		&:hover {
			color: var(--secondary);
			padding: var(--space-2) var(--space-1);
		}
	}
}