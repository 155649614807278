:root {
	--secondary: #5F758E;
	--neutral-dark: #2F2F2F;
	--neutral-light: #ebe9e9;
	
	--space-1: 1.5rem; 
	--space-2: 3rem;
	--space-4: 6rem;
	--space-5: 12rem;
}
@media (width < 640px) {
	:root {
		--h1: 4rem;
		--h2: 2rem;
		--h3: 1.5rem;
		--h4: 1.25rem;
		--p: 1rem;
		--body-small: 0.75rem;
		--body-med: 1rem;
		--body-large: 1rem;

		--margin: 5%;
	}
}
@media (width < 1024px) and (width >= 640px) {
	:root {
		--h1: 6rem;
		--h2: 2rem;
		--h3: 1rem;
		--h4: 1rem;
		--p: 1rem;
		--body-small: 0.75rem;
		--body-med: 1;
		--body-large: 1rem;
		
		--margin: 5%;
	}
}
@media (width >= 1024px) {
	:root {
		--h1: 8rem;
		--h2: 3rem;
		--h3: 2rem;
		--h4: 1.5rem;
		--p: 1rem;
		--body-small: 0.75rem;
		--body-med: 1rem;
		--body-large: 1.5rem;

		--margin: 7.5%;
	}
}

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

a {
	color: var(--neutral-dark);
	text-decoration: none;
	font-size: var(--p);
}
h1 {
	font-size: var(--h1);
	line-height: 100%;
	font-weight: 200;
	letter-spacing: -0.5rem;
}
@media (width < 1000px) {
	h1 {
		font-size: var(--h1);
		line-height: 100%;
		font-weight: 200;
		letter-spacing: -0.2rem;
	}
}
h2 {
	font-size: var(--h2);
	white-space: nowrap;
	line-height: 100%;
	font-weight: 300;
	letter-spacing: -0.1rem;
}
h3 {
	font-size: var(--h3);
	line-height: 100%;
	font-weight: 350;
}
h4 {
	font-size: var(--h4);
	line-height: 100%;
}
p {
	line-height: 175%;
	font-size: var(--p);
}

body {
	font-family: "Montserrat", sans-serif;
	width: 100vw;
	overflow-x: hidden;
	background-color: var(--neutral-light);
}

#root[data-state="locked"] {
	overflow: hidden;
	max-height: 100dvh;
}
#root[data-state="unlocked"] {
	max-height: 100%;
}
#content {
	display: flex;
	flex-direction: column;
	gap: var(--space-5);
	position: relative;
	padding: 0 var(--margin);
}
main {
	display: flex;
	flex-direction: column;
	gap: var(--space-5);
	position: relative;
}

/* ------------------------------------------- Visual Effects --------------------------------------------------------- */
.link {
	position: relative;
	text-transform: uppercase;

	&:after {
		content: '';
		position: absolute;
		left: 0;
		bottom: -8px;
		width: 0;
		height: 4px;
		background-color: var(--secondary);
		transition: 0.5s width ease-out;
	}

	&:hover {
		&:after {
			width: 100%;
		}
	}
}
@media (width < 640px) {
	.link {
		position: relative;
		text-transform: uppercase;
	
		&:after {
			content: '';
			position: absolute;
			left: 0;
			bottom: -8px;
			width: 100%;
			height: 4px;
			background-color: var(--secondary);
			transition: 0.5s width ease-out;
		}
	}
}

.section-content {
	display: flex;
	flex-direction: column;
	gap: var(--space-1);
}

.word {
	opacity: 0;
	transition: all 0.25s;
}

#page-load {
	position: fixed;
	height: 100vh;
	width: 100vw;
	z-index: 20;
	background-color: var(--neutral-dark);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.load-text-area {
	position: relative;
	height: 90vh;
	width: 90vw;
}
.load-text-title {
	position: absolute;
	left: 0;
	top: 0;
}
.load-text-title h4 {
	color: var(--neutral-light);
	text-transform: uppercase;
	margin-bottom: 8px;
}
.load-text-wrapper {
	position: absolute;
	height: var(--h1);
	width: calc(var(--h1) * 2);
	overflow: hidden;
	top: 100%;
	right: 0%;
	transform: translateY(-100%);
}
.load-text {
	position: absolute;
}
.load-text h4 {
	font-size: var(--h1);
	text-align: end;
	color: var(--neutral-light);
	line-height: 100%;
}

@media (width < 640px) {
	.load-text h4 {
		font-size: var(--h4);
		text-align: end;
		color: var(--neutral-light);
		line-height: 100%;
	}
	.load-text-wrapper {
		position: absolute;
		height: var(--h4);
		width: calc(var(--h4) * 2);
		overflow: hidden;
		top: 100%;
		right: 0%;
		transform: translateY(-100%);
	}
}